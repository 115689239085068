import React from 'react';
import cx from 'classnames';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import CarouselArrowButtonAdapter from 'components/CarouselArrowButtonAdapter';
import { TABLET_MIN, TABLET_MAX, DESKTOP_MIN } from 'libs/universal/utils/device';

import styles from './Carousel.module.less';

type CarouselWrapperProps = {
  responsiveOverride?: ResponsiveType;
  className?: string;
  arrowStyleOverride?: string;
  rightArrowStyleOverride?: string;
  leftArrowStyleOverride?: string;
  smallerArrows?: boolean;
  children: (JSX.Element | null)[];
};

const CarouselWrapper: React.FC<CarouselWrapperProps> = ({
  children,
  responsiveOverride,
  arrowStyleOverride,
  rightArrowStyleOverride,
  leftArrowStyleOverride,
  className,
  smallerArrows = false,
  ...props
}) => {
  const defaultResponsiveSettings: ResponsiveType = {
    desktop: {
      breakpoint: { max: 10000, min: DESKTOP_MIN },
      items: 3,
    },
    tablet: {
      breakpoint: { max: TABLET_MAX, min: TABLET_MIN },
      items: 3,
    },
  };
  return (
    <Carousel
      {...props}
      responsive={responsiveOverride || defaultResponsiveSettings}
      ssr
      infinite
      renderButtonGroupOutside
      customLeftArrow={
        <CarouselArrowButtonAdapter
          component="button"
          direction="left"
          className={cx(
            arrowStyleOverride || styles.arrow,
            leftArrowStyleOverride,
            'react-multiple-carousel__arrow'
          )}
          smaller={smallerArrows}
        />
      }
      customRightArrow={
        <CarouselArrowButtonAdapter
          component="button"
          direction="right"
          className={cx(
            arrowStyleOverride || styles.arrow,
            rightArrowStyleOverride,
            'react-multiple-carousel__arrow'
          )}
          smaller={smallerArrows}
        />
      }
      swipeable
      keyBoardControl
      containerClass={cx(styles.carousel, className)}
    >
      {children}
    </Carousel>
  );
};

export default CarouselWrapper;
