import { ArrowButton } from '@zola/zola-ui/src/components/ArrowButton';

type ArrowButtonProps = {
  className?: string;
  children?: JSX.Element | JSX.Element;
  component?: 'button' | 'a' | 'div';
  direction: 'left' | 'right' | 'down' | 'up';
  smaller?: boolean;
  carouselState?: never;
};

// Hack to prevent warning of prop assigned to html element.
function CarouselArrowButtonAdapter(props: ArrowButtonProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { carouselState, ...rest } = props;
  return (
    <ArrowButton
      {...rest}
      aria-label={
        rest.direction === 'left' || rest.direction === 'up' ? 'Previous slides' : 'Next slides'
      }
    />
  );
}

export default CarouselArrowButtonAdapter;
